.chip {
  border-radius: 50%;
  width: 22px;
  height: 22px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: var(--font-family-primary);
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.4px;
  background-color: var(--colour-text-600);
  border: none;
  outline: none;
  color: var(--colour-text-0);

  &:focus {
    background-color: var(--colour-text-800);
    outline: 1px solid var(--colour-primary-100);
  }

  &:hover {
    background-color: var(--colour-text-800);
  }

  &.interactive {
    width: 18px;
    height: 18px;

    &:focus {
      background-color: unset;
      outline: none;
    }

    &:hover {
      background-color: unset;
    }
  }
}

.account-menu-chip {
  width: 46.5px;
  height: 46.5px;
  font-size: 24px;
  border: 2px solid var(--colour-surface-75);
}

.chip-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  background-color: unset;
  border: none;
  border-radius: 50%;

  &:focus {
    outline: 2px solid var(--colour-primary-300);

    .chip {
      background-color: var(--colour-text-800);
    }
  }

  &:hover {
    .chip {
      background-color: var(--colour-text-800);
    }
  }

  &[aria-expanded="true"],
  &:hover {
    background-color: var(--colour-surface-200);
  }
}
